export const ALL_LOGIN_TYPES = ['password', 'email', 'google'] as const;
export type LoginType = (typeof ALL_LOGIN_TYPES)[number];

/**
 * Generally these are query string params that are set due to off-platform
 * navigation, such as someone clicking a magic link or entering the venue via
 * calendar invite. They may or may not overlap with those in
 * useFeatureQueryParam.
 */
export type KnownRedirectParams = {
  'redirect-to': string;

  // Login / Access
  'auto-submit': boolean;
  'login-type': LoginType;
  'user-not-found': boolean;

  // Available for pre-filling registration forms
  email: string;
  name: string;

  // SCORM / LMS
  'learner-id': string;
  'learner-name': string;

  // Org
  'org-id': string;
  'as-org-id': string;

  // venue / courses
  'guest-users': string;

  // lms/iframes/etc
  'in-app-escape': 'disabled' | 'force-prompt';
};

export function setKnownRedirectParam(
  params: URLSearchParams,
  key: keyof KnownRedirectParams,
  value: Nullable<KnownRedirectParams[keyof KnownRedirectParams]>
) {
  if (value) {
    params.set(key, `${value}`);
  }
}

export function getKnownRedirectParam(
  params: URLSearchParams,
  key: keyof KnownRedirectParams
) {
  return params.get(key);
}

export function deleteKnownRedirectParam(
  params: URLSearchParams,
  key: keyof KnownRedirectParams
) {
  params.delete(key);
}

export function paramsWithRedirectTo(
  to: Nullable<string>,
  params = new URLSearchParams()
) {
  if (to) {
    // URLSearchParams will auto encode the url
    params.set('redirect-to', to);
  }
  return params;
}
